import { ReactComponent as YahooIcon } from "@/assets/svg/yahoo-logo.svg";
import { ReactComponent as AOLIcon } from "@/assets/svg/aol-logo.svg";
import { ReactComponent as Office365Icon } from "@/assets/svg/office365-logo.svg";
import { ReactComponent as ZohoIcon } from "@/assets/svg/zoho-logo.svg";
import { ReactComponent as FastmailIcon } from "@/assets/svg/fastmail-logo.svg";
import { ReactComponent as ICloudIcon } from "@/assets/svg/icloud-logo.svg";
import { ReactComponent as GMXIcon } from "@/assets/svg/gmx-logo.svg";
import { ReactComponent as GodaddyIcon } from "@/assets/svg/godaddy-logo.svg";
import { SMTPProvider } from "@/types";

export const SMTP_PROVIDERS: SMTPProvider[] = [
  {
    name: "Yahoo",
    host: "smtp.mail.yahoo.com",
    portWithSSL: "465",
    port: "587",
    icon: <YahooIcon />,
  },
  {
    name: "AOL",
    host: "smtp.aol.com",
    portWithSSL: "465",
    port: "587",
    icon: <AOLIcon />,
  },
  {
    name: "Office 365",
    host: "smtp.office365.com",
    portWithSSL: "587",
    port: "587",
    icon: <Office365Icon />,
  },
  {
    name: "Zoho",
    host: "smtp.zoho.com",
    portWithSSL: "465",
    port: "587",
    icon: <ZohoIcon />,
  },
  {
    name: "Fastmail",
    host: "smtp.fastmail.com",
    portWithSSL: "465",
    port: "587",
    icon: <FastmailIcon />,
  },
  {
    name: "iCloud",
    host: "smtp.mail.me.com",
    portWithSSL: "587",
    port: "587",
    icon: <ICloudIcon />,
  },
  {
    name: "GMX",
    host: "smtp.gmx.com",
    portWithSSL: "465",
    port: "587",
    icon: <GMXIcon />,
  },
  {
    name: "GoDaddy",
    host: "smtpout.secureserver.net",
    portWithSSL: "465",
    port: "465",
    icon: <GodaddyIcon />,
  },
  {
    name: "Other",
    host: "",
    portWithSSL: "",
    port: "",
  },
];
