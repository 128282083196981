import React, { FC } from "react";
import { Radio, RadioGroup } from "@nextui-org/react";
import { SMTPProvider } from "@/types";
import { SMTP_PROVIDERS } from "@/constants/smtpProviders";

interface Props {
  activeProvider: SMTPProvider | null;
  setActiveProvider: (value: string) => void;
}

export const SMTPProviderSelect: FC<Props> = ({
  activeProvider,
  setActiveProvider,
}) => {
  return (
    <div className="w-full">
      <h4 className="mb-4">Select SMTP Provider</h4>

      <RadioGroup
        orientation="horizontal"
        value={activeProvider?.host}
        onValueChange={setActiveProvider}
        classNames={{
          wrapper: "w-full flex gap-3",
        }}
      >
        {SMTP_PROVIDERS.map((provider) => (
          <SMTPServiceOption key={provider.host} data={provider} />
        ))}
      </RadioGroup>
    </div>
  );
};

const SMTPServiceOption: FC<{ data: SMTPProvider }> = ({ data }) => {
  return (
    <Radio
      value={data.host}
      classNames={{
        base: "m-0 border rounded-lg relative data-[selected=true]:border-brand-700 data-[selected=true]:bg-brand-700/10 [&_svg]:data-[selected=true]:block hover:bg-brand-700/10",
        label:
          "w-20 h-20 text-sm text-gray-900 static flex flex-col items-center justify-center gap-2",
        labelWrapper: "ml-0",
        wrapper: "hidden w-0",
      }}
    >
      {data?.icon && (
        <div className="w-10 h-full flex justify-center items-center">
          {data.icon}
        </div>
      )}
      <h4 className="text-sm font-normal">{data?.name}</h4>
    </Radio>
  );
};
